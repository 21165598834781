<template>
  <div>
    <div
      v-if="!authUser"
      class="navbar-listing" :class="navbarScrollListingActived ? 'navbar-listing-scroll-bg-visitor' : ''">
      <div
        @click="$router.push('/')"
        class="icon-emitus-navbar-listing">
        <img
          src="https://emituscdn.com/LOGO-ABOGADUS..svg"
          alt="">
      </div>
      <div class="container-emitus-navbar-listing">
        <div class="buttons-nabvar-listing">
          <!-- <div class="select-navbar-landing">
            <img class="mr-2" src="@/assets/images/listing/select-language.svg" alt="">
            <select>
              <option>English</option>
            </select>
          </div>
          <div v-if="!authUser" class="select-navbar-landing">
            <select>
              <option>EUR</option>
            </select>
          </div> -->
          <div class="container-btn-login-signup">
            <div class="btn-login-signup">
              <!-- <a @click="$router.push('/signup')">Signup</a> -->
              <a @click="clickShowContinue">Ingresar</a>
            </div>
            <div>
              <button class="btn-sell-my-time btn-sell-my-time-desktop">
                <a @click="$router.push('/soy-abogado')">Soy abogado</a>
                </button>
            </div>
          </div>
        </div>
        <div @click="clickShowMenuMobile" class="menu-mobile-listing">
          <img src="@/assets/images/listing/menu-mobile.svg" alt="">
        </div>
        <div v-if="!authUser" @click="clickShowMenuMobile" class="menu-desktop-listing">
          <img src="@/assets/images/listing/menu-mobile.svg" alt="">
        </div>
      </div>
      <!-- modal menu  -->
      <div v-if="menuActive" class="modal-menu">
        <div class="container-modal-menu">
          <div class="content-modal-menu-1">
            <div class="text-basier-medium btn-login-signup-modal-menu mb-3">
              <a class="text-basier" @click="$router.push('/signup')">Registrarme</a>
              |
              <a class="text-basier" @click="clickShowContinue">Ingresar</a>
            </div>
            <div>
              <button @click="$router.push('/soy-abogado')" class="btn-sell-my-time button-primary-tall-large mt-3">Soy abogado</button>
            </div>
          </div>
          <!---
          <div class="border-bottom-separation my-4"></div>
          <div class="content-modal-menu-2">
            <div class="select-navbar-landing-modal-menu">
              <select>
                <option>English</option>
              </select>
            </div>
            <div class="select-navbar-landing-modal-menu">
              <select>
                <option>EUR</option>
              </select>
            </div>
          </div>
          -->
        </div>
      </div>
      <!-- Continue Modal -->
      <b-modal
          v-model="showContinue"
          full-screen
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal
          :can-cancel="false">

          <div>
            <Continue
              @close="showContinue = false"
            />
          </div>

      </b-modal>
    </div>

    <div v-if="authUser" class="navbar-listing" :class="navbarScrollListingActived ? 'navbar-listing-scroll-bg' : ''">
      <div @click="$router.push('/')" class="icon-emitus-navbar-listing">
        <!-- <img src="@/assets/images/listing/icon-emitus-listing-2.svg" alt=""> -->
        <img src="https://emituscdn.com/LOGO-ABOGADUS..svg" alt="">
      </div>
      <div class="container-emitus-navbar-listing">
        <div @click="clickShowMenuMobile" class="menu-mobile-listing">
          <img src="@/assets/images/listing/menu-mobile.svg" alt="">
        </div>
        <div
          v-if="!authUser"
          @click="clickShowMenuMobile"
          class="menu-desktop-listing">
          <img src="@/assets/images/listing/menu-mobile.svg" alt="">
        </div>
      </div>
      <!-- modal menu  -->
      <div v-if="menuActive" class="modal-menu">
        <div class="container-modal-menu">
          <div class="border-bottom-separation my-4"></div>
          <div class="content-modal-menu-2">
            <div class="select-navbar-landing-modal-menu">
              <select>
                <option>English</option>
              </select>
            </div>
            <div class="select-navbar-landing-modal-menu">
              <select>
                <option>EUR</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- nabvar logged avatar scroll -->
    <!-- <NavbarLoggedAvatar
      v-if="navbarLogged"
      >
    </NavbarLoggedAvatar> -->
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import Continue from '@/components/modals/continue.vue'
export default {
  components: {
    Continue
  },
  data () {
    return {
      menuActive: false,
      authUser: false,
      navbarScrollListingActived: false,
      navbarLogged: false,
      showContinue: false,
      scrollCurrent: 0
    }
  },
  async created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
    })
    window.addEventListener('scroll', this.scrollNavbarListing)
    this.notify.$on('updateAuthUser', (user) => {
      this.authUser = user
      // console.log(this.authUser)
    })
    // console.log(this.authUser)
    this.notify.$on('hiddeModalContinue', data => {
      this.showContinue = data
    })
    this.notify.$on('hiddeMenuModalHeaderListing__', (data) => {
      this.menuActive = data
    })
  },
  watch: {
    authUser: function () {

    },
    menuActive: function () {
      this.notify.$emit('modalHeaderListinActive__', this.menuActive)
    }
  },
  methods: {
    clickShowContinue () {
      this.showContinue = true
    },
    scrollNavbarListing () {
      const resp = window.scrollY
      if (!this.authUser) {
        if (resp > 400) {
          this.navbarScrollListingActived = true
        } else if (resp <= 400) {
          this.navbarScrollListingActived = false
        }
      }
      if (this.authUser) {
        if (resp > 400) {
          this.navbarLogged = true
        } else if (resp <= 400) {
          this.navbarLogged = false
        }
      }
    },
    clickShowMenuMobile () {
      if (this.menuActive === false) {
        this.menuActive = true
      } else if (this.menuActive === true) {
        this.menuActive = false
      }
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollNavbarListing)
    this.notify.$off('hiddeModalContinue')
    this.notify.$off('hiddeMenuModalHeaderListing__')
  }
}
</script>

<style scoped>
.btn-sell-my-time a {
  color: #000;
}
.icon-emitus-navbar-listing img {
  width: 123px;
  left: 25px;
  margin-top: 9px;
}
.btn-login-signup-modal-menu a{
  color: #000;
}
.btn-login-signup-modal-menu {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.content-modal-menu-2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.select-navbar-landing-modal-menu select {
  width: 100%;
  border: none;
  background: transparent;
  color: #393939;
}
.border-bottom-separation {
  border: 1px solid #D1D1D1;
  width: 100%;
}
.container-modal-menu {
  width: 85%;
  margin: auto;
}
.content-modal-menu-1 {
  text-align: center;
}
.modal-menu {
  position: absolute;
  top: 48px;
  right: 22px;
  background: #fff;
  border-radius: 8px;
  width: 195px;
  height: 141px;
  padding: 35px 0px;
  box-shadow: 0px 5.38078px 8.07117px rgba(0, 0, 0, 0.15);
  z-index: 7;
}
.select-navbar-landing select {
  background: transparent;
  border: none;
  color: #fff;
}
.navbar-listing {
  display: flex;
  padding: 0px 25px;
  height: 65px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  justify-content: space-between;
  align-items: center;
}
.container-emitus-navbar-listing {
  display: flex;
  width: 40%;
}
.menu-mobile-listing {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.buttons-nabvar-listing {
  display: none;
}
.menu-desktop-listing {
  display: none!important;
}
@media (min-width: 1024px) {
  .icon-emitus-navbar-listing img {

  left: 45px;
 }
  .navbar-listing-scroll-bg {
    /* background: #0D1239!important; */
    position: fixed;
    z-index: 1;
  }
  .navbar-listing-scroll-bg-visitor {
    background: #000!important;
    position: fixed;
    z-index: 1;
  }
  .buttons-nabvar-listing {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
    justify-content: flex-end;
    align-items: center;
  }
  .menu-mobile-listing {
    display: none;
  }
  .menu-desktop-listing {
    display: flex;
    width: auto;
    justify-content: flex-end;
  }
  .container-btn-login-signup {
    display: flex;
    gap: 20px;
    margin-right: 20px;
  }
  .btn-login-signup {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    width: 174px;
    height: 38px;
    padding:  7px 10px ;
    color: #fff;
    font-family: "Basier Circle";
    text-align: center;
  }

  .btn-sell-my-time-desktop {
    width: 160px;
    height: 38px;
    background: linear-gradient(92.24deg, #fff, #fff);
    border: none;
    border-radius: 0px;
    font-family: "Basier Circle";
    font-weight: 500;
    font-size: 14px;
    color: #000 !important;
  }

  .btn-login-signup a {
    color: #fff!important;
  }
}

</style>
