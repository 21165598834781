<template>
  <div id="navSide_menu_listing">
    <div v-if="!authUser" style="position: relative; z-index: 10;">
      <NavbarLising/>
    </div>
    <div v-if="authUser" @click="$router.push('/')" class="icon-emitus-navbar-listing">
      <!-- <img src="@/assets/images/listing/icon-emitus-listing-2.svg" alt=""> -->
      <img src="@/assets/images/publicProfile/logo_white.svg" alt="">
    </div>
    <!-- Public Profile Info Modal -->
    <b-modal
        v-model="showPublicProfileModal"
        full-screen
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal
        :can-cancel="false">

        <div>
          <PublicProfileListing
            :profileId="profileId"
            @close="showPublicProfileModal = false"
          />
        </div>

    </b-modal>

    <div class="video-header-listing">
      <video
        :src="'https://player.vimeo.com/progressive_redirect/playback/670877036/rendition/720p/720p.mp4?loc=external&signature=cc939335e9afe02e1459baf0a3c1230a1e26eed7300e3aa8df16825e43e7cb09'"
        playsinline
        muted
        autoplay
        loop
      ></video>
    </div>
    <div @click="hiddeModalHeaderMenu" class="header-listing">
      <div class="container-header-listing">
        <div class="content-header-listing">
          <h1 class="text-basier mb-3">Consultas online con los mejores abogados</h1>
          <p class="text-basier mb-5">La forma más simple y eficiente de resolver tus dudas legales</p>
        </div>
        <div id="search_id" class="search-header-listing">
          <section class="relative-listing-mobile">
            <div class="search-container">
            <b-field>
              <b-autocomplete
                rounded
                name="searchBar"
                id="searchBar"
                placeholder="Mercantil, familiar, ..."
                icon="magnify"
                >
                <!-- <template #empty>No results found</template> -->
              </b-autocomplete>
            </b-field>
            </div>
            <img @click="showMenuListingMobile" class="menu-filter-search-mobile" src="@/assets/images/listing/menu-filter-mobile.svg" alt="">
          </section>
        </div>
      </div>
    </div>

    <div @click="hiddeModalHeaderMenu" class="container-listing-professional-desktop">
      <!-- navSide desktop and mobile (modal) -->
      <div id="menu_listing_navSide" class="navSide-listing">
        <div class="header-menu-listing-filter-fixed is-hidden-desktop is-flex is-justify-content-space-between is-flex-direction-column px-3 pt-4 pb-4" style="border-bottom: 1px solid #ECECEC;">
          <div class="is-flex is-justify-content-space-between">
            <p class="text-basier" style="color: #0D1239; font-size: 20px;">Filters</p>
            <span @click="hiddeMenuListingMobile"><img src="@/assets/images/listing/cancel-listing-filter.svg" alt=""></span>
          </div>
          <b-field class="is-hidden-desktop mt-6 mb-3">
            <b-select v-model="orderProfiles" class="sort-by-mobile">
              <option selected hidden>
                Ordenar por
              </option>
              <option value="new-talents">
                Nuevos talentos
              </option>
              <option value="rating">
                Rating
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="container-menu-navside-desktop">
          <!-- pricing -->
          <b-menu class="menu-list-listing py-5">
            <b-menu-list>
              <b-menu-item icon="settings" expanded>
                <template #label="props">
                  <span
                    class="text-basier-medium dark-ble-emitus">
                    Precio
                  </span>
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                </template>
                <section class="is-flex is-flex-direction-column" style="gap: 15px;">
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="all">
                            Cualquier precio
                        </b-radio>
                    </b-field>
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="<10"
                         >
                            €10 o menos
                        </b-radio>
                    </b-field>
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="10and25"
                            >
                            €10 to €25
                        </b-radio>
                    </b-field>
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="25and50"
                           >
                            €25 to €50
                        </b-radio>
                    </b-field>
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="50and80"
                            >
                            €50 to €80
                        </b-radio>
                    </b-field>
                    <b-field>
                        <b-radio v-model="priceMinuted"
                            native-value="80>"
                            >
                            €80 +
                        </b-radio>
                    </b-field>
                </section>
              </b-menu-item>
            </b-menu-list>
          </b-menu>
          <!-- Rating -->
          <b-menu class="menu-list-listing py-5">
            <b-menu-list>
              <b-menu-item icon="settings" expanded>
                <template #label="props">
                  <span
                    class="text-basier-medium dark-ble-emitus">
                    Rating
                  </span>
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                </template>
                <section
                  class="is-flex is-flex-direction-column section_rating_listing" style="gap: 15px;">
                    <b-field>
                      <b-radio v-model="rating" native-value="all">
                        <div class="is-flex">
                          <p class="mr-2">Todos</p>
                          <b-rate
                            size="is-small"
                            :max="1"
                            disabled
                            >
                          </b-rate>
                        </div>
                      </b-radio>
                    </b-field>
                    <b-field>
                      <b-radio v-model="rating" :native-value="5">
                        <div class="is-flex">
                          <p class="mr-2">5</p>
                          <b-rate
                            size="is-small"
                            :max="1"
                            disabled
                            >
                          </b-rate>
                        </div>
                      </b-radio>
                    </b-field>
                    <b-field>
                      <b-radio v-model="rating" :native-value="4">
                        <div class="is-flex">
                          <p class="mr-2">4</p>
                          <b-rate
                            size="is-small"
                            :max="1"
                            disabled
                            >
                          </b-rate>
                        </div>
                      </b-radio>
                    </b-field>

                    <b-field>
                      <b-radio v-model="rating" :native-value="3">
                        <div class="is-flex">
                          <p class="mr-2">3</p>
                          <b-rate
                            size="is-small"
                            :max="1"
                            disabled
                            >
                          </b-rate>
                        </div>
                      </b-radio>
                    </b-field>

                </section>
              </b-menu-item>
            </b-menu-list>
          </b-menu>
          <!-- Language
          <b-menu class="menu-list-listing py-5">
            <b-menu-list>
              <b-menu-item icon="settings" expanded>
                <template #label="props">
                  <span
                    class="text-basier-medium dark-ble-emitus">
                    Language
                  </span>
                  <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
                </template>
                <section class="is-flex is-flex-direction-column" style="gap: 15px;">
                  <b-field>
                    <b-radio v-model="language"
                      native-value="English">
                      English
                    </b-radio>
                  </b-field>
                  <b-field>
                    <b-radio v-model="language"
                      native-value="Spanish"
                      >
                      Spanish
                    </b-radio>
                  </b-field>
                  <b-field>
                    <b-radio v-model="language"
                      native-value="France"
                      >
                      French
                    </b-radio>
                  </b-field>
                  <b-field class="search-language-listing">
                    <b-autocomplete
                      v-model="language"
                      placeholder="Search language..."
                      :keep-first="keepFirst"
                      :open-on-focus="openOnFocus"
                      :data="filteredDataObj"
                      @select="option => (selected = option)"
                      :clearable="clearable"
                    >
                    </b-autocomplete>
                  </b-field>
                </section>
              </b-menu-item>
            </b-menu-list>
          </b-menu>-->
        </div>
        <div class="is-hidden-desktop btn-filters-mobile-navside">
          <button @click="hiddeMenuListingMobile" class="text-basier">
            Filter Profiles
          </button>
        </div>

      </div>
      <!-- content listing -->
      <div class="container-content-listing">
        <!-- header content listing -->
        <div class="content-listing-header is-flex">
          <b-field>
            <b-select v-model="orderProfiles">
              <option selected hidden>
                Ordenar por
              </option>
              <option value="new-talents">
                Nuevos talentos
              </option>
              <option value="rating">
                Rating
              </option>
            </b-select>
          </b-field>
          <div v-if="iconLinesSelect" class=" is-flex is-hidden-touch">
            <!-- <img v-if="iconCubesDisable" @click="clickIconCubesSelect" src="@/assets/images/listing/cubo-disable.svg" alt=""> -->
            <img
              class="icon-view-list"
              heigth="18px"
              @click="clickIconCubesSelect"
              src="https://emituscdn.com/cubo-view-icon.svg"
              alt="">
          </div>
          <div v-if="iconCubesSelect" class="is-flex is-hidden-touch">
            <!-- <img v-if="iconLinesDisable" @click="clickIconLinesSelect" src="@/assets/images/listing/line-disable.svg" alt=""> -->
            <img
              class="icon-view-list"
              heigth="18px"
              @click="clickIconLinesSelect"
              src="https://emituscdn.com/listing-icon.svg"
              alt="">
          </div>
        </div>

        <!-- card professional -->
        <div :class="iconLinesSelect ? 'modeProfileVertical-desktop' : 'modeProfileHorizontal-desktop'">

        <div
            class="content-profiles-professional-mobile content-profiles-professional-desktop"
            v-for="(profile, idx) in profiles"
            :key="idx"
            @click="showPublicProfile(profile.username)"
            >
            <div style="position: relative;" class="img-avatar-professional-desktop">
              <div class="avatar-card-professional-mobile">
                <img :src="profile.avatar" alt="">
              </div>
              <div class="content-card-professional-mobile">
                <div v-if="!iconLinesSelect" class="width-container-content-card-professional">
                  <div class="is-flex is-hidden-tablet section_rating_listing">
                    <p v-if="!profile.rateasprofessional" class="text-basier" >Nuevo</p>
                    <!-- <p v-if="profile.rateasprofessional" class="text-basier">{{profile.rateasprofessional.toString().includes('.') === false ? profile.rateasprofessional.toFixed(1) : profile.rateasprofessional}}</p> -->
                    <p v-if="profile.rateasprofessional" class="text-basier">{{profile.rateasprofessional.toFixed(1)}}</p>
                    <b-rate
                      v-if="profile.rateasprofessional"
                      size="is-small"
                      :max="1"
                      active>
                    </b-rate>
                  </div>
                  <div class="header-card-professional-mobile is-hidden-touch mb-4" style="height: 115px;">
                    <div>
                      <div class="text-name-title-card-desktop-white  text-tranformation-capitalize">
                        <p class="text-basier-bold" >
                        {{profile.firstname}} {{profile.lastname}}
                        </p>
                        <span class="text-basier-medium is-size-medium-text mt-2">
                          {{profile.profile.title}}
                        </span>
                      </div>
                      <div class="is-flex-wrap-wrap is-flex is-justify-content-space-between mt-3" style="max-width: 155px;">
                        <div class="is-flex mr-3 section_rating_listing">
                          <p v-if="!profile.rateasprofessional" class="text-basier-medium is-size-text-normal gray-ultra-light mb-1">Nuevo</p>
                          <!-- <p v-if="profile.rateasprofessional" class="text-basier">{{profile.rateasprofessional.toString().includes('.') === false ? profile.rateasprofessional.toFixed(1) : profile.rateasprofessional}}</p> -->
                          <p v-if="profile.rateasprofessional" class="text-basier-medium is-size-text-normal mr-1 mb-1">{{profile.rateasprofessional.toFixed(1)}}</p>
                          <b-rate
                            v-if="profile.rateasprofessional"
                            size="is-small"
                            :max="1"
                            active>
                          </b-rate>
                        </div>
                        <div>
                          <p class="text-basier" style="font-weight: 500;"> {{(profile.durations[0].price)}} € / {{(profile.durations[0].minutes)}} min</p>
                        </div>
                      </div>
                    </div>
                    <button class="btn_info text-basier-medium is-hidden-touch mb-1">
                      + info
                    </button>
                  </div>

                  <div class="container-skill-profesional-mobile">
                    <span class="text-nunito is-size-small-text" v-for="skill in profile.profile.skills" :key="skill">{{skill}} /</span>
                  </div>
                </div>
                <button class="btn_info text-basier-medium is-hidden-tablet">
                   + info
                </button>
              </div>
            </div>

            <!-- content profiles vertical -->
            <div v-if="iconLinesSelect" class="width-container-content-card-professional">
              <div style="width: 100%;">
                <div class="is-flex is-hidden-tablet section_rating_listing">
                  <p v-if="!profile.rateasprofessional" class="text-basier">Nuevo</p>
                  <p v-if="profile.rateasprofessional" class="text-basier">{{profile.rateasprofessional.toFixed(1)}}</p>
                  <b-rate
                    v-if="profile.rateasprofessional"
                    size="is-small"
                    :max="1"
                    active>
                  </b-rate>
                </div>
                <div class="header-card-professional-mobile is-hidden-touch is-flex">
                  <div class="is-flex is-justify-content-space-between" style="width: 100%;">
                    <div>
                      <p class="text-basier mb-2" style="font-weight: 500; font-size: 21px; color: #000;">
                      {{profile.firstname}} {{profile.lastname}}
                      </p>
                      <span class="text-basier" style="font-weight: normal; font-size: 19px; color: #8E8E8E;">
                        {{profile.nationality}} {{profile.profile.title}}
                      </span>
                    </div>
                    <div class="is-flex is-justify-content-space-between is-align-items-baseline" style="gap: 40px;">
                      <div class="is-flex section_rating_listing">
                        <p v-if="!profile.rateasprofessional" class="text-basier" >Nuevo</p>
                        <p v-if="profile.rateasprofessional" class="is-flex text-basier">{{profile.rateasprofessional.toFixed(1)}}</p>
                        <b-rate
                          v-if="profile.rateasprofessional"
                          class="is-flex"
                          size="is-small"
                          :max="1"
                          active>
                        </b-rate>
                      </div>
                      <p class="is-flex text-basier" style="font-weight: 500; font-size: 16px; color: #000;"> {{(profile.durations[0].price)}} €/ {{(profile.durations[0].minutes)}} min</p>
                    </div>
                  </div>
                </div>
                <div class="is-flex is-justify-content-space-between mt-5" style="gap: 25px;">
                  <div>
                    <div class="mb-5">
                      <p class="text-nunito" style="font-weight: normal; font-size: 14px; color: #393939;">{{profile.profile.description}}</p>
                    </div>
                    <div class="container-skill-profesional-mobile">
                      <span class="text-nunito" v-for="skill in profile.profile.skills" :key="skill">{{skill}} /</span>
                    </div>
                  </div>
                  <div class="is-flex">
                    <button class="btn_info_vertical text-basier-medium is-hidden-touch">
                      + info
                    </button>
                  </div>
                </div>
              </div>
              <button class="btn_info text-basier-medium is-hidden-desktop">
                + info
              </button>
            </div>

            <div class="header-card-professional-mobile is-hidden-desktop">
              <div>
              <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap">
                <p class="text-basier-medium text-tranformation-capitalize text-overflow">
                {{profile.firstname}} {{profile.lastname}}
                </p>
                <div class="is-flex is-align-items-baseline section_rating_listing">
                  <p v-if="!profile.rateasprofessional" class="text-basier is-size-small-text gray-medium-scale" >Nuevo</p>
                  <p v-if="profile.rateasprofessional" class="is-flex text-basier-medium is-size-text-normal mr-1">{{profile.rateasprofessional.toFixed(1)}}</p>
                  <b-rate
                    v-if="profile.rateasprofessional"
                    class="is-flex is-align-items-baseline"
                    size="is-small"
                    :max="1"
                    active>
                  </b-rate>
                </div>
                <!-- <span class="text-basier">
                  {{profile.nationality}} {{profile.profile.title}}
                </span> -->
              </div>
              <div>
                <p class="text-basier dark-gray-emitus"> {{(profile.durations[0].price)}} € / {{(profile.durations[0].minutes)}} min</p>
              </div>
              </div>

              <div class="container-skill-profesional-mobile">
                <span class="text-nunito" v-for="skill in profile.profile.skills" :key="skill">{{skill}} /</span>
              </div>

              <div class="btn-view-my-profile">
                <button class="text-basier">Ver mi perfil</button>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
    <div style="height: 200px;"></div>
    <!-- Footer -->
    <FooterLanding/>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import NavbarLising from '@/components/listing/navbarListing.vue'
import ProfileService from '@/services/ProfileService'
// import ratingVue from '../components/modals/rating.vue'
import PublicProfileListing from '@/views/PublicProfileListing.vue'
import FooterLanding from '@/components/landing/FooterLanding'
const languagesOpt = [
  'Español',
  'Catalán',
  'Gallego',
  'Valenciano',
  'Gallego',
  'Euskera',
  'Inglés',
  'Francés',
  'Árabe',
  'Mandarin',
  'Ruso',
  'Alemán',
  'Portugués',
  'Japonés',
  'Hindi',
  'Malay',
  'Persa',
  'Swahili',
  'Tamil',
  'Italiano',
  'Holandés',
  'Bengali',
  'Turco',
  'Vietnamita',
  'Polaco',
  'Javanese',
  'Punjabi',
  'Thai',
  'Koreano']

// const languagesOpt = [
//   'English',
//   'French',
//   'Spanish',
//   'Arabic',
//   'Mandarin',
//   'Russian',
//   'German',
//   'Portuguese',
//   'Japanese',
//   'Hindi',
//   'Malay',
//   'Persian',
//   'Swahili',
//   'Tamil',
//   'Italian',
//   'Dutch',
//   'Bengali',
//   'Turkish',
//   'Vietnamese',
//   'Polish',
//   'Javanese',
//   'Punjabi',
//   'Thai',
//   'Korean']

export default {
  name: 'Listing',
  components: {
    NavbarLising,
    PublicProfileListing,
    FooterLanding
  },

  // DATA
  data () {
    return {
      showPublicProfileModal: false,
      profileId: null,
      scroll: 0,
      languagesOpt,
      profilesRaw: null,
      profiles: null,
      isSwitchedCustom: 'Yes',
      radio: 'default',
      keepFirst: false,
      openOnFocus: false,
      name: '',
      selected: null,
      clearable: false,
      iconCubesSelect: true,
      iconCubesDisable: true,
      iconLinesSelect: false,
      iconLinesDisable: true,
      english: null,
      spanish: null,
      fance: null,
      language: null,
      profilesFilters: null,
      profilesFiltersLanguages: null,
      profilesFiltersRating: null,
      priceMinuted: 'all',
      orderProfiles: 'Sort by',
      rating: null,
      authUser: null,
      menuActive: null,
      searchTerm: ''
    }
  },

  metaInfo () {
    return {
      title: 'Abogadus | Consultas Legales Online',
      meta: [
        {
          name: 'description',
          content: 'Consultas online con los mejores abogados. La forma más simple y eficiente de resolver tus dudas legales.'
        },
        {
          name: 'og:description',
          content: 'Consultas online con los mejores abogados. La forma más simple y eficiente de resolver tus dudas legales.'
        },
        {
          name: 'site_name',
          content: 'Abogadus'
        },
        {
          name: 'og:site_name',
          content: 'Abogadus'
        },
        {
          name: 'image',
          content: 'https://emituscdn.com/Abogadus.jpg'
        },
        {
          name: 'og:image',
          content: 'https://emituscdn.com/Abogadus.jpg'
        },
        {
          name: 'title',
          content: 'Abogadus | Consultas Legales Online'
        },
        {
          name: 'og:title',
          content: 'Abogadus | Consultas Legales Online'
        },

        {
          name: 'MobileOptimized',
          content: 'width'
        },
        {
          name: 'HandheldFriendly',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        },
        {
          name: 'theme-color',
          content: '#000000'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Abogadus'
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'og:type',
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  },

  watch: {

    language: function () {
      this.filterProfiles(this.language, this.rating, this.priceMinuted)
    },
    rating: function () {
      this.filterProfiles(this.language, this.rating, this.priceMinuted)
    },
    priceMinuted: function () {
      this.filterProfiles(this.language, this.rating, this.priceMinuted)
    },
    // cada vez que cambie el profilesFilters le daremos ese valor a profiles para que se muestre en pantalla
    profilesFilters: function () {
      this.profiles = this.profilesFilters
    },
    orderProfiles: function () {
      this.changeOrderProfile()
    },
    emptySearchTerm: function () {
      if (!this.emptySearchTerm) {
        document.documentElement.scrollTop = (document.body.clientHeight - 50)
      }
    }
  },
  computed: {
    filteredDataObj () {
      return this.languagesOpt.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        )
      })
    },

    emptySearchTerm () {
      if (this.searchTerm && this.searchTerm.length > 0) {
        return false
      } else {
        return true
      }
    }
    // rateString: function () {
    //   if (this.profile.rateasprofessional.toString().includes('.') === false) {
    //     return this.profile.rateasprofessional.toFixed(1)
    //   } else {
    //     return this.profile.rateasprofessional
    //   }
    // }
  },
  // CREATED
  async created () {
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
    })
    // Bring profiles
    if (!this.authUser) {
      document.addEventListener('scroll', this.searchFixedNavbar)
    }
    ProfileService
      .getAllPublicProfiles()
      .then(profiles => {
        this.profilesRaw = profiles.data
        this.profiles = this.profilesRaw
        this.profiles.sort(function () { return Math.random() - 0.5 })
        // console.log(this.profiles)
      })
      .catch(err => {
        console.log('Error: getAllPublicProfiles', err)
      })
    this.notify.$on('closeModalPublicProfile', () => {
      window.scroll(0, this.scroll)
      this.showPublicProfileModal = false
      const publicProfile = document.body
      publicProfile.classList.remove('noScroll')
    })
    this.notify.$on('modalHeaderListinActive__', (data) => {
      this.menuActive = data
    })
  },
  mounted () {
    this.inputSearch()
    // Auth User
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      if (this.authUser) {
        if (window.innerWidth > 1024) {
          window.scroll(0, 785)
        } else {
          window.scroll(0, window.innerHeight - 65)
        }
      }
    })

    const searchBarInput = document.getElementById('searchBar')
    searchBarInput.focus()
  },

  // METHODS
  methods: {
    hiddeModalHeaderMenu () {
      if (this.menuActive === true) {
        this.notify.$emit('hiddeMenuModalHeaderListing__', false)
      }
    },
    // Show Public Profile
    showPublicProfile (profileId) {
      // console.log(document.getElementById('navSide_menu_listing'))
      this.profileId = profileId
      this.scroll = document.documentElement.scrollTop
      document.body.classList.add('noScroll')
      this.showPublicProfileModal = true
    },

    // ordenar perfiles
    changeOrderProfile () {
      const ratingOrder = this.profiles.sort((a, b) => {
        if (this.orderProfiles === 'new-talents') {
          return (!a.rateasprofessional ? 0 : a.rateasprofessional) - (!b.rateasprofessional ? 0 : b.rateasprofessional)
        }
        if (this.orderProfiles === 'rating') {
          return (!b.rateasprofessional ? 0 : b.rateasprofessional) - (!a.rateasprofessional ? 0 : a.rateasprofessional)
        }
      })
      // console.log('>>>>>>>>>>>>>>', ratingOrder)
      this.profiles = ratingOrder
    },
    // ---- FILTRO ----
    async filterProfiles (language, rating, priceMinuted) {
      this.profiles = this.profilesRaw
      // Filtrar por language
      const filteredbyLanguage = await this.profiles.filter((profile) => {
        if (language) {
          if (profile.profile.languages === null) {
            return false
          } else {
            return profile.profile.languages.includes(language)
          }
        } else {
          return true
        }
      })
      // Filtrar por rating
      const filteredbyRating = await filteredbyLanguage.filter((profile) => {
        if (rating) {
          if (profile.rateasprofessional === null) {
            return false
          } else {
            if (rating === 'all') {
              return profile
            }
            if (rating === 5) {
              return profile.rateasprofessional === 5
            }
            if (rating === 4) {
              return profile.rateasprofessional >= 4
            }
            if (rating === 3) {
              return profile.rateasprofessional >= 3
            }
          }
        } else {
          return true
        }
      })
      // filtrar por precio por minuto
      const filteredPriceMinute = await filteredbyRating.filter((profile) => {
        if (priceMinuted) {
          if (profile.durations === []) {
            return false
          } else {
            if (priceMinuted === 'all') {
              return profile.durations[0].price >= 0
            }
            if (priceMinuted === '<10') {
              return profile.durations[0].price <= 10
            }
            if (priceMinuted === '10and25') {
              return profile.durations[0].price >= 10 && profile.durations[0].price < 25
            }
            if (priceMinuted === '25and50') {
              return profile.durations[0].price >= 25 && profile.durations[0].price < 50
            }
            if (priceMinuted === '50and80') {
              return profile.durations[0].price >= 50 && profile.durations[0].price < 80
            }
            if (priceMinuted === '80>') {
              return profile.durations[0].price >= 80
            }
          }
        }
      })
      this.profiles = filteredPriceMinute
    },
    inputSearch () {
      const searchBar = document.getElementById('searchBar')
      searchBar.addEventListener('keyup', (e) => {
        this.profiles = this.profilesRaw
        const searchString = e.target.value.toLowerCase()
        this.searchTerm = searchString
        const searchedProfiles = this.profiles.filter(profile => {
          if (searchString.length > 0) {
            if (
              profile.firstname === undefined ||
              profile.lastname === undefined ||
              profile.profile.description === undefined) {
              return false
            } else {
              return (
                profile.firstname.toLowerCase().includes(searchString) ||
                profile.lastname.toLowerCase().includes(searchString) ||
                profile.profile.description.toLowerCase().includes(searchString) ||
                profile.profile.skills.some(s => s.toLowerCase().includes(searchString))
              )
            }
          } else {
            return this.profilesRaw
          }
        })
        this.profiles = null
        document.documentElement.scrollTop = (document.body.clientHeight - 50)
        this.profiles = searchedProfiles
      })
    },
    clickScrollTop () {
      document.documentElement.scrollTop = (document.body.clientHeight - 50)
    },

    clickIconCubesSelect () {
      this.iconCubesDisable = false
      this.iconCubesSelect = true
      if (this.iconLinesSelect === true) {
        this.iconLinesSelect = false
        this.iconLinesDisable = true
      }
    },
    clickIconCubesDisable () {
      this.iconCubesDisable = true
      this.iconCubesSelect = false
    },
    clickIconLinesSelect () {
      this.iconLinesDisable = false
      this.iconLinesSelect = true
      if (this.iconCubesSelect === true) {
        this.iconCubesSelect = false
        this.iconCubesDisable = true
      }
    },
    clickIconLinesDisable () {
      this.iconLinesDisable = true
      this.iconLinesSelect = false
    },
    showMenuListingMobile () {
      this.scroll = document.documentElement.scrollTop
      document.getElementById('menu_listing_navSide').classList.add('show-menu-mobile-navSide')
      document.body.classList.add('noScroll')
    },
    hiddeMenuListingMobile () {
      document.getElementById('menu_listing_navSide').classList.remove('show-menu-mobile-navSide')
      document.body.classList.remove('noScroll')
      window.scroll(0, this.scroll)
      // document.documentElement.scrollTop = 0
    },
    searchFixedNavbar () {
      const resp = window.scrollY
      // console.log(resp)
      if (resp > 400) {
        document.getElementById('search_id').classList.add('search-fixed-navbar')
      } else if (resp <= 400) {
        document.getElementById('search_id').classList.remove('search-fixed-navbar')
      }
    },
    // Go to profile
    // goToProfile (username) {
    //   this.$router.push(`/${username}`)
    // },

    // Filter

    // Search

    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  },
  destroyed () {
    document.removeEventListener('scroll', this.searchFixedNavbar)
    this.notify.$off('updateAuthUser')
    this.notify.$off('modalHeaderListinActive__')
  }

}
</script>

<style>
.btn-filters-mobile-navside {
  position: fixed;
  bottom: 28px;
  left: 26px;
}
.btn-filters-mobile-navside button {
  width: 215px;
  height: 55px;
  background: linear-gradient(272.43deg, #9A55F2 -2.34%, #6E3DEA 93.28%);
  border-radius: 14px;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}
.sort-by-mobile .select select {
  width: 280px!important;
}
.search-language-listing {
  width: 150px;
}
.btn-view-my-profile button {
  width: 145px;
  height: 38px;
  background: linear-gradient(265.62deg, #000 1.5%, #000 95.54%);
  border-radius: 0px;
  border: none;
  color: #FFFFFF;
}
.icon-emitus-navbar-listing img {
  width: 80px;
  filter: opacity(0.5);
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 3;
}
.container-content-listing {
  min-height: 1000px;
  margin-top: 34px;
}
/*#search_id {
  display: flex;
  justify-content: center;
}*/
#searchBar .autocomplete .dropdown-menu {
  display: none;
}
#searchBar::placeholder {
  color: #979797 !important;
}
.search-language-listing input::placeholder,
.search-language-listing input {
  color: #979797!important;
}
.container-listing-professional-desktop .select select {
  color: #929292!important;
  background: #FFFFFF!important;
  border: 1px solid #D4D4D4!important;
  box-sizing: border-box!important;
  border-radius: 5px!important;
}
.container-listing-professional-desktop .field:not(:last-child) {
  margin-bottom: 0px!important;
}
.container-listing-professional-desktop select option {
  color: #929292!important;
  background: #FFFFFF!important;
}
.header-menu-listing-filter-fixed {
  position: sticky;
  top: 0;
  background: #FFFFFF!important;
  z-index: 10;
}
.btn_info {
  font-size: 14px;
  width: 65px;
  height: 44px;
  border: 0.9px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 0px;
  background: transparent;
  color: #FFFFFF;
}
.btn_info_vertical {
  font-size: 14px;
  width: 86px;
  height: 44px;
  border: 0.9px solid #000;
  box-sizing: border-box;
  border-radius: 1px;
  background: transparent;
  color: #0D1239;
}
.search-language-listing {
  width: 94%;
}
.show-menu-mobile-navSide {
  display: block!important;
}
.menu-list-listing {
  border-bottom: 1px solid #ECECEC;
}
.section_rating_listing .rate .icon {
  color: #F9D85D;
}

.menu-list a {
  font-size: 15px;
  color: #0d1239 !important;
}
#navSide_menu_listing .menu-list a.is-active {
  background-color: transparent!important;
  color: #111747!important;
}
#navSide_menu_listing .menu-list li ul {
  border-left: none!important;
}
#navSide_menu_listing .switch {
  gap: 10px;
}
.search-fixed-navbar .field:not(:last-child) {
  margin: 0px!important;
}
.video-header-listing {
  position: relative;
  height: 100vh;
  object-fit: contain;
  width: 100%;
  filter: brightness(0.8);
}
.video-header-listing video{
  position: relative;
  height: 100vh;
  object-fit: cover;
  width: 100%;
  filter: brightness(0.8);
}
.header-listing {
  top: 0;
  right: 0;
  left: 0;
  position: absolute;
  height: 70vh;
  background-position: center;
  background-size: cover;
}
.container-header-listing {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.content-header-listing {
  text-align: center;
  color: #fff;
  text-shadow: 5px 5px 10px #00000040;
  margin-bottom: 30px;
}
.content-header-listing h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
}
.content-header-listing p {
  font-weight: normal;
  font-size: 15px;
  text-shadow: 5px 5px 10px #00000040;
}

.search-container {
  margin-left: 0px ;
}
@media (max-width: 1024px) {

  .search-container {
    margin-left: 0px ;
  }
  .btn_info {
    display: none;
  }
  .search-fixed-navbar {
    position: fixed;
    top: 0;
    z-index: 11;
    width: 100%;
    left: 0;
    /* max-width: 517px; */
    /* background: #5347DB; */
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img-avatar-professional-desktop {
    height: 210px;
    width: 139px;
  }
  .width-container-content-card-professional {
    width: 68%;
    display: none;
  }
  .container-skill-profesional-mobile {
    display: flex;
    /* width: 75%; */
    flex-wrap: wrap;
    min-width: 174px;
    height: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .container-skill-profesional-mobile span {
    font-size: 13px !important;
    line-height: 18px !important;
    color: #606060 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .avatar-card-professional-mobile {
    position: relative;
    height: 210px;
    width: 139px;
  }
  .avatar-card-professional-mobile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
  .content-card-professional-mobile {
    position: absolute;
    height: 100%;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    background: linear-gradient(0deg, #0D1239 -6.15%, rgba(13, 18, 57, 0.963542) 8.15%, rgba(13, 18, 57, 0) 42.86%);
    align-items: flex-end;
    padding-bottom: 18px;
    color: #fff;
    border-radius: 2px;
  }
  .header-card-professional-mobile {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 197px;
    margin: auto auto auto 25px;
  }
  .header-card-professional-mobile p {
    line-height: 26px;
    max-width: 160px;
  }
  .header-card-professional-mobile span {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #393939;
  }

  .content-profiles-professional-mobile {
    display: flex;
    /* flex-direction: column-reverse; */
    margin-top: 15px;
    padding-left: 15px;
  }
  .relative-listing-mobile {
    position: relative;
    width: 90%;
    margin: auto;
    max-width: 350px;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.09));
  }

  .menu-filter-search-mobile {
    position: absolute;
    top: 18px;
    right: 18px;
  }
  .content-listing-header {
    display: none !important;
    justify-content: flex-end;
    margin-top: 53px;
    padding: 0px 11px 28px 11px;
    border-bottom: 1px solid #ECECEC;
  }

  .content-listing-header img {
    height: 18px;
    margin-top: 10px;
  }

  .navSide-listing {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 11;
    overflow: auto;
    display: none;
  }
}
@media (min-width: 1024px) {
  .search-fixed-navbar .control.has-icons-left .input:focus ~ .icon {
    color: #FFFFFF!important;
  }
  .search-fixed-navbar {
    position: fixed;
    top: 10px;
    z-index: 6;
    width: 80%;
    max-width: 517px;
  }
  .modeProfileHorizontal-desktop .width-container-content-card-professional {
    width: 100%;
  }
  .modeProfileVertical-desktop .width-container-content-card-professional {
    width: 100%;
    max-width: 510px;
  }
  .modeProfileVertical-desktop .content-card-professional-mobile {
    width: 100%;
  }
  .header-card-professional-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .text-name-title-card-desktop-white p {
    font-weight: 500;
    font-size: 22px;
    width: 155px;
  }
  .text-name-title-card-desktop-white span {
    font-weight: normal;
  }
  .avatar-card-professional-mobile {
    height: 100%;
    border-radius: 20px;
  }
  .avatar-card-professional-mobile img {
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  .modeProfileVertical-desktop {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-left: 30px;
  }
  .modeProfileVertical-desktop .content-card-professional-mobile {
    position: absolute;
    height: 100%;
    bottom: 0;
    display: flex;
    width: 100%;
    background: linear-gradient(0deg, #0000 -9.15%, rgba(0, 0, 0, 22.963542) 3.15%, rgba(0, 0, 0, 0.4) 46.86%);
    border-radius: 5px;
  }
  .modeProfileVertical-desktop .img-avatar-professional-desktop {
    width: 190px;
    height: 334px;
    border-radius: 20px;
  }
  .modeProfileVertical-desktop .content-profiles-professional-desktop {
    display: flex;
    gap: 30px;
  }
  .modeProfileHorizontal-desktop {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  /* justify-content: center; */
    gap: 22px;
  }
  .modeProfileHorizontal-desktop .content-card-professional-mobile {
    position: absolute;
    height: 100%;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
    background: linear-gradient(0deg, #0000 -9.15%, rgba(0, 0, 0, 22.963542) 3.15%, rgba(0, 0, 0, 0.4) 46.86%);
    align-items: flex-end;
    padding-bottom: 18px;
    color: #fff;
    border-radius: 5px;
  }
  .modeProfileHorizontal-desktop .container-skill-profesional-mobile,
  .modeProfileVertical-desktop .container-skill-profesional-mobile {
    display: flex;
    flex-wrap: wrap;
    height: 59px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .modeProfileHorizontal-desktop .content-profiles-professional-desktop {
    height: 100%;
  }
  .modeProfileHorizontal-desktop .content-profiles-professional-desktop {
    height: 100%;
  }
  .modeProfileHorizontal-desktop .img-avatar-professional-desktop {
    width: 298px;
    height: 526px;
    border-radius: 20px;
  }
  .content-listing-header {
    justify-content: flex-end;
    padding: 24px 68px 24px 0px;
    gap: 15px;
  }
  .container-menu-navside-desktop {
    width: 83%;
    margin: 0 auto;
  }
  .container-listing-professional-desktop {
    display: flex;
  }
  .container-listing-professional-desktop .navSide-listing {
    width: 20%;
    border-right: 1px solid #ECECEC;
  }
  .container-listing-professional-desktop .container-content-listing {
    width: 80%;
    padding: 10px 40px 10px 40px;
  }
  .menu-filter-search-mobile {
    display: none;
  }
  .content-header-listing h1 {
    font-weight: 200;
    font-size: 65px;
    line-height: 75px;
  }
  .content-header-listing p {
    font-size: 20px;
  }
  .header-listing {
    height: 100vh;
  }
  .video-header-listing video{
    height: 100vh;
    filter: brightness(0.8);
  }
  .video-header-listing {
    height: 100vh;
    filter: brightness(0.8);
  }
  .container-header-listing {
    padding-bottom: 20%;
  }
  .search-header-listing {
    width: 300px;
    margin: 0 auto;
  }
  .search-fixed-navbar {
    left: 15%;
    z-index: 10;
  }
  .search-fixed-navbar input {
    background: rgba(36, 36, 36, 0.31);
    border: 1px solid rgba(65, 65, 65, 0.41);
    border-radius: 38px;
    color: #fff!important;
  }

  .search-fixed-navbar input:hover {
    border: 1px solid #DCDCDC !important;
  }
}
</style>
