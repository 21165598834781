<template>
  <div
    id="public_profile">

    <div v-if="profileActive">

          <!-- v-if="this.authUser" -->
        <div
          class="goBack"
          @click="closeModalPublicProfile"
        >
          <img src="@/assets/images/publicProfile/arrow-to -back-public.svg" alt="Go Back">
          <p
            class="goBackText text-basier"
          >
            Volver
          </p>
        </div>
        <div class="line-gray-bottom"></div>

      <div
        v-if="!authUser"
        style="z-index: 3;">
        <NavBar />
      </div>
      <div
        class="container_desktop_content">

        <!-- Video -->
        <div
          v-if="profile2"
          class="emitus-video-landing"
          style="position: relative">

          <div
            class="container_avatar_public">

            <!-- Progress Bar -->
            <b-progress
              v-if="profile2.profilevideo && profile2.profilevideo != ''"
              id="progressbar"
              class="front"
              style="display: none; margin-bottom: -3px; height: 5px;"
              :type="type"
              :max="100"
              :value="valueVideo"
              @change="displayProgressBar"
            ></b-progress>

            <!-- Video source -->
            <video
              v-if="profile2.profilevideo && profile2.profilevideo != ''"
              :src="profile2.profilevideo"
              ref="bgvid"
              id="bgvid"
              playsinline
              muted
              autoplay
              loop
            ></video>

            <!-- Fallback image -->
            <div
              v-if="!profile2.profilevideo || profile2.profilevideo === ''"
              class="img_public_avatar">
              <img :src="profile.avatar" alt="avatar"
              style="width: 100%; height: 100%; object-fit: cover;">
            </div>

            <!-- Filter for mobile -->
            <div
              v-if="filterRemove"
              class="video-height-video-desktop filter-video-landing"
            >
              <div
                class="m-center content-filter">
                <div
                  class="content-video-center">
                  <img
                    v-if="profile2.profilevideo"
                    id=""
                    class="is-45x45"
                    src="@/assets/images/landing/icon-play-desktop.svg"
                  />

                  <!-- container info user Mobile-->
                  <!--- name user mobile-->
                  <div
                    class="container-tag-line">
                    <h4
                      class="text-tranformation-capitalize title-medium-big text-basier-medium white">
                      {{profile.firstname}}  {{profile.lastname}}
                    </h4>

                    <!-- Ocupation user mobile-->
                    <p
                      v-if="profile2"
                      class="text-transformation-capitalize subtitle-small-basier  text-basier-medium mt-2"
                    >
                      {{profile2.title}}
                    </p>

                    <!-- Rating Mobile User-->

                    <section
                      id="section_rating"
                      v-if="rate"
                      class="is-flex is-align-items-center" style="gap: 5px;">
                      <span class="text-basier-medium mr-3">{{rateInput}}</span>
                      <!-- <input
                        class="value-rate Basier-normal-Medium"
                        v-model="rateInput" min="0" :max="maxs"
                        :step="isDisabled ? '.1' : '1'" disabled> -->
                      <b-rate
                        size="is-small"
                        v-model="rate"
                        :max="maxs"
                        :disabled="isDisabled">
                      </b-rate>
                      <p class="text-basier is-size-7" style="display: contents;">({{userRates}})</p>
                        <!-- custom-text="(595)" -->
                    </section>
                    <p class="mt-3 text-basier gray-ultra-light" v-if="!rate">Nuevo</p>
                    <div
                      class="is-flex is-flex-direction-row"
                      style="gap: 30px;">
                      <div
                        class="is-flex is-align-items-center">
                        <img
                          class="mr-2"
                          src="@/assets/images/publicProfile/location-mobile.svg"
                          alt="Location">
                        <p
                          class="text-basier"
                          style="font-size: 12px;">
                          {{profile.countryorigin}}
                        </p>
                      </div>
                      <div
                        class="is-flex is-align-items-center lenguage-location">
                        <img
                          class="mr-2"
                          src="@/assets/images/publicProfile/globe-mobile.svg" alt="Globe">
                        <div
                          class="is-flex container-languages-profile container-language-public-desktop">
                          <p
                            v-for="(lenguage, index) in showLanguages" :key="index"
                            class="text-basier is-flex">
                            {{lenguage.slice(0, 2)}}
                            <span class="mr-1 ml-1"> | </span>
                          </p>
                          <p>... {{profile2.languages.length}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- C2A mobile -->
            <button
              v-if="(profile2.description && profile2.description !== '') ||  (profile2.education && profile2.education !== '') || (profile2.experience && profile2.experience !== '')"
              v-on:click="scrollUnder"
              class="btn_info text-basier-medium is-hidden-desktop">
              + Info
            </button>

            <!-- Controls video -->
            <div
              v-if="profile2.profilevideo && profile2.profilevideo != ''"
              id="filer_front"
              class="filter-front">

              <div>

                <!-- Show video on load -->
                <div
                  id="load"
                  @click="displayProgressBar"
                  class="invisible-front"
                  onclick="document.getElementById('bgvid').currentTime = 0;
                          document.getElementById('load').style.display = 'none';
                          document.getElementById('pause').style.display = 'block';
                          document.getElementById('progressbar').style.display = 'block';
                          document.getElementById('bgvid').muted = false;
                          "
                >
                  <div
                    class="center is-horizontal-center">
                    <figure
                      class="image is-64x64">
                      <img src="" />
                    </figure>
                  </div>
                </div>

                <!-- Stop video -->
                <div
                  id="pause"
                  style="display: none"
                  class="invisible-front"
                  @click="stopVideo"
                  onclick="document.getElementById('bgvid').pause();
                          document.getElementById('pause').style.display = 'none';
                          document.getElementById('play').style.display = 'block';
                          document.getElementById('progressbar').style.display = 'none';
                          "
                >
                  <img
                    src="@/assets/images/landing/invisible.png" />
                </div>

                <!-- Play video -->
                <div
                  id="play"
                  style="display: none"
                  @click="displayProgressBar"
                  class="invisible-front"
                  onclick="document.getElementById('bgvid').play();
                          document.getElementById('play').style.display = 'none';
                          document.getElementById('pause').style.display = 'block';
                          document.getElementById('progressbar').style.display = 'block';
                          "
                >
                  <div class="center is-horizontal-center">
                    <figure class="image is-64x64">
                      <img src="" />
                    </figure>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <!-- C2A Mobile -->
        <div id="btn_connect" class="container-btn-Join-emitus" style="position: fixed!important">
          <p class="price-profesional text-basier"><strong>{{profile.durations[0].price}} {{profile.currency === 'USD' ? '$' : '€'}} </strong><span>/ {{profile.durations[0].minutes}} min</span></p>
          <a
            @click="showModal"
            style="margin: 0 auto;"
            class="btn-Join-emitus is-vertical-center"
          >
            <img
              src="https://emituscdn.com/icon-videocall.svg"
              class="mr-2"
              width="23px"
            >
            <span>Reservar videollamada</span>
          </a>
        </div>

        <!-- Main Content  -->
        <div
          class="container_content_public">

          <!-- Navegacion principal -->

          <!-- Contenido del profesional -->
          <div
            class="mt-2 name-user-public is-hidden-touch">
            <!-- name user desktop-->
            <h4
              class="text-tranformation-capitalize title-big-regular text-basier-medium white dark-blue-emitus mb-2">
              {{profile.firstname}}  {{profile.lastname}}
            </h4>

            <!-- Ocupation user desktop-->
            <span
              v-if="profile2"
              class="text-tranformation-capitalize subtitle-big-basier text-basier gray-medium">
              {{profile2.title}}
            </span>

            <!-- rating user desktop-->
            <div class="container-rating-desktop is-flex is-justify-content-space-between">
              <section id="section_rating" class="is-flex is-align-items-center" style="gap: 5px;" v-if="rate">
                <span class="text-basier-medium mr-3">{{rateInput}}</span>
                <!-- <input
                  class="value-rate text-basier-medium"
                  v-model="rateInput" min="0" :max="maxs"
                  :step="isDisabled ? '.1' : '1'"
                  disabled
                > -->
                <b-rate
                  size="is-small"
                  v-model="rate"
                  :max="maxs"
                  :disabled="isDisabled"
                ></b-rate>
                <p class="text-basier gray-medium is-mini-small-text" style="display: contents;">({{userRates}})</p>
                  <!-- custom-text="(595)" -->

              </section>
              <p class="text-basier gray-medium-scale mt-2" v-if="!rate">Nuevo</p>

              <!-- City user desktop-->
              <div
                class="is-flex is-align-items-center">
                <img
                  class="mr-3"
                  src="@/assets/images/publicProfile/location.svg"
                  alt="Location"
                >
                <p
                  class="text-basier  city-user-public is-size-text-normal">
                  {{profile.countryorigin}}
                </p>
              </div>
            </div>

            <!--  Language desktop-->
            <div
              class="is-flex is-align-items-center mt-1"
              style="gap: 37px; ">

              <div
                class="is-flex is-align-items-center lenguage-location">

                <img
                  class="mr-3"
                  src="@/assets/images/publicProfile/globe.svg"
                  alt="Globe"
                >
                <div
                  class="is-flex is-align-items-center container-language-public-desktop"
                >
                  <p
                    v-for="(lenguage, index) in showLanguages" :key="index" class="text-basier is-flex mt-1">
                    {{lenguage.slice(0,2)}}
                    <span
                      class="mr-1 ml-1">
                      |
                    </span>
                  </p>
                  <p>... {{profile2.languages.length}}</p>
                  <!-- <p class="text-basier ml-2" style="font-size: 13.09px;">Es</p> -->
                </div>
              </div>
            </div>

          </div>

          <!-- About me -->
          <div>
            <AboutPublic
              v-if="profile2.description"
              :description="profile2.description"
              :skills="profile2.skills"
            />
          </div>

          <!-- C2A -->
          <div
            id="btn_connect_desktop"
            class="container-btn-Join-emitus-desktop is-hidden-touch is-flex is-justify-content-space-between">

            <p class="price-profesional text-basier"><strong>{{profile.durations[0].price}} {{profile.currency === 'USD' ? '$' : '€'}} </strong><span>/ {{profile.durations[0].minutes}} min</span></p>

            <button
              @click="showModal"
              class="btn-Join-emitus is-vertical-center is-flex is-align-items-center is-justify-content-center">
              <img
                src="https://emituscdn.com/icon-videocall.svg"
                class="mr-2"
                width="23px"
              >
              <span>Reservar</span>
            </button>

          </div>

        </div>

        <!-- redes sociales -->
        <div
          class="social is-hidden-touch"
          >
          <div
            v-if="profile2"
            class="container_social"
          >
            <a
              v-if="profile2.linkedin"
              :href="profile2.linkedin"
              target="_blank">
              <img
                src="https://emituscdn.com/linkedin-icon.svg"
                alt="linkedin"
                width="18px"
              >
            </a>
            <a
              v-if="profile2.facebook"
              :href="profile2.facebook"
              target="_blank">
              <img
                src="@/assets/images/publicProfile/facebook.svg"
                alt="facebook"
              >
            </a>
            <a
              v-if="profile2.instagram"
              :href="profile2.instagram"
              target="_blank">
              <img
                src="@/assets/images/publicProfile/instagram.svg"
                alt="instagram"
              >
            </a>
            <a
              v-if="profile2.twitter"
              :href="profile2.twitter"
              target="_blank">
              <img
                src="@/assets/images/publicProfile/twitter.svg"
                alt="twitter"
              >
            </a>
            <a
              v-if="profile2.tiktok"
              :href="profile2.tiktok"
              target="_blank">
              <img
                src="@/assets/images/publicProfile/tiktok-icon.svg"
                alt="tiktok-icon"
              >
            </a>
          </div>
        </div>

        <!-- Modal Booking -->
        <div
          v-if="modal"
          id="modal"
          style="position: fixed; top: 0; left: 0px; width: 100%; height: 100%; background: rgba(9, 13, 41, 0.95); display: block; justify-content: space-between; z-index: 31;">

          <div
            @click="hiddeModal"
            style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: transparent">
            .
          </div>
          <div
            class="is-flex  general-container">
            <div
              v-if="modalChild"
              class="modal-container">
              <Booking
                :key="bookingKey"
                :professionalUsername="profile.username"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Information about Prfofessional -->
      <div
        v-if="profile2.experience || profile2.education"
        class="content-profesional">
        <div v-if="profile2.experience">
          <h2
            class="text-basier experience-title">
            Experiencia
          </h2>
          <p
            class="paragraph-content experience-text dark-gray-emitus">
            {{profile2.experience}}
          </p>
        </div>
        <div v-if="profile2.education">
          <h2
            class="text-basier education-title">
            Educación
          </h2>
          <p
            class="paragraph-content dark-gray-emitus education-text">
            {{profile2.education}}
          </p>
        </div>
      </div>

      <!-- Social icons -->
      <div
        v-if="profile2.linkedin || profile2.facebook || profile2.instagram || profile2.twitter || profile2.tiktok"
        class="social is-hidden-desktop">
        <div
          v-if="profile2"
          class="container_social">
          <a
            v-if="profile2.linkedin"
            :href="profile2.linkedin"
            target="_blank">

            <img
              src="@/assets/images/publicProfile/linkedin.svg"
              alt="linkedin"
            >
          </a>
          <a
            v-if="profile2.facebook"
            :href="profile2.facebook"
            target="_blank">

            <img
              src="@/assets/images/publicProfile/facebook.svg"
              alt="facebook"
            >
          </a>
          <a
            v-if="profile2.instagram"
            :href="profile2.instagram"
            target="_blank">

            <img
              src="@/assets/images/publicProfile/instagram.svg"
              alt="instagram"
            >
          </a>
          <a
            v-if="profile2.twitter"
            :href="profile2.twitter"
            target="_blank">
              <img
                src="@/assets/images/publicProfile/twitter.svg"
                alt="twitter"
              >
          </a>
          <a
            v-if="profile2.tiktok"
            :href="profile2.tiktok"
            target="_blank">
            <img
              src="@/assets/images/publicProfile/tiktok-icon.svg"
              alt="tiktok-icon"
            >
          </a>
        </div>
      </div>
    </div>

    <!-- modal profile desactived -->

    <div
      v-if="!profileActive"
      class="modal-profile-desactive"
      >
      <!-- Header modal disable profile | Not logged in-->

      <div
        v-if="authUser === null"
        class="is-justify-content-space-between header-modal-disable-profile"
      >
        <a
          href="/">
          <img
            src="@/assets/images/landing/emitus-logo-gray-2.svg"
            alt="logo emitus"
            class="logo-emitus-modal-disable"
          >
        </a>
        <div
          class="is-flex is-align-items-center">
          <div
            class="mr-5"
          >
            <!-- Login -->
            <!-- <button
              @click="clickShowContinue"
              class="link-text-purple-no-decoration text-basier-medium is-size-small-text"
            >
              Login
            </button> -->
          </div>
          <!-- <button
            @click="$router.push('/signup')"
            class="button-extra-small text-basier-medium">
            Signup
          </button> -->
        </div>
      </div>
      <!-- Continue Modal -->
      <b-modal
          v-model="showContinue"
          full-screen
          :destroy-on-hide="true"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal
          :can-cancel="false">

          <div>
            <Continue
              @close="showContinue = false"
            />
          </div>

      </b-modal>
      <!-- Fin header | Not logged in-->
      <div
        class="cancel-modal-profile-desactived is-hidden-desktop">
        <img
          @click="closeModalPublicProfile"
          src="@/assets/images/money/cancel.svg"
          alt=""
        >
      </div>
      <div
        class="container-profile-desactive">
        <div>
          <img
            src="https://emituscdn.com/profile-inactive.png"
            alt=""
            class="image-disabled-modal"
          >
        </div>
        <div
          class="content-profile-desactive">
          <p
            class="ml-4 title-regular">
            Lo sentimos!
          </p>
          <span
            class="mt-5 ml-4 text-nunito is-size-extra-text dark-gray-emitus">
            <span
              class="text-tranformation-capitalize">
              {{profile.firstname}}
            </span>No esta disponible en este momento. Inténtalo más tarde.
          </span>
          <button
            v-if="authUser !== null"
            @click="closeModalPublicProfile"
            class="ml-5 mt-6 button-primary-mini">
              OK
          </button>
          <button
            v-if="authUser === null"
            class="ml-5 mt-6 button-primary-mini"
            @click="closeModalPublicProfile">
              OK
          </button>
        </div>
      </div>
    </div>

    <!-- fin modal disable profile-->
    <!-- Footer -->
    <FooterLanding/>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import Booking from '@/components/booking/booking'
import ProfileService from '@/services/ProfileService'
// import CalendarService from '@/services/CalendarService'
import AboutPublic from '@/components/publicProfile/aboutPublic'
import NavBar from '@/components/publicProfile/navBar'
import Continue from '@/components/modals/continue.vue'
import mixpanel from 'mixpanel-browser'
import FooterLanding from '@/components/landing/FooterLanding'

export default {
  name: 'PublicProfile',
  components: {
    AboutPublic,
    NavBar,
    Booking,
    Continue,
    FooterLanding
  },

  metaInfo () {
    if (this.profile) {
      return {
        title: this.profile.firstname + ' | emitus',
        meta: [
          {
            name: 'description',
            content: this.profile2.description
          },
          {
            name: 'og:description',
            content: this.profile2.description
          },
          {
            name: 'site_name',
            content: 'Emitus'
          },
          {
            name: 'og:site_name',
            content: 'Emitus'
          },
          {
            name: 'image',
            content: this.profile.avatar
          },
          {
            name: 'og:image',
            content: this.profile.avatar
          },
          {
            name: 'title',
            content: this.profile.firstname + ' ' + this.profile.lastname
          },
          {
            name: 'og:title',
            content: this.profile.firstname + ' ' + this.profile.lastname
          },

          {
            name: 'MobileOptimized',
            content: 'width'
          },
          {
            name: 'HandheldFriendly',
            content: 'width'
          },
          {
            name: 'apple-mobile-web-app-capable',
            content: 'width'
          },
          {
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black-translucent'
          },
          {
            name: 'theme-color',
            content: '#000000'
          },
          {
            name: 'apple-mobile-web-app-title',
            content: 'Emitus'
          },
          {
            name: 'mobile-web-app-capable',
            content: 'yes'
          },
          {
            name: 'og:type',
            property: 'og:type',
            content: 'website'
          }
        ]
      }
    } else {
      return {
        title: 'emitus'
      }
    }
  },

  data () {
    return {
      filterRemove: true,
      type: 'is-primary',
      nIntervId: 0,
      valueVideo: null,
      rate: null,
      userRates: null,
      maxs: 5,
      isDisabled: true,
      about: true,
      premium: false,
      courses: false,
      profile: {}, // part of user we need to bring profile info
      modal: false,
      profile2: {}, // Profile inside the "user" we bring using populate
      logged: false,
      booking: {},
      authUser: null,
      modalChild: false,
      entryContent: true,
      currentSteps: 0,
      timeSelect: {},
      minutes: 0,
      price: 0,
      languages: [],
      showLanguages: [],
      // To send as booking request
      language: null,
      bookingKey: 0,
      show: null,
      approved: null,
      profileActive: null,
      showContinue: false
    }
  },
  props: {
    profileId: String
  },
  created () {
    this.notify.$on('hiddeModalContinue', (data) => {
      this.showContinue = data
    })
    this.notify.$emit('visitingPublicProfile')
    this.showModalChild()
    // Get user from Firebase
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
    })

    // Close modal
    this.notify.$on('close', () => {
      this.hiddeModal()
    })
    this.notify.$on('updateUser', (user) => {
      this.user = user
      // console.log(this.user, 'usuario con que entro')
    })
    // console.log('007', this.profileId)
    ProfileService.getPublicProfile(this.profileId)
      .then(profile => {
        if (profile.data) {
          this.profile = profile.data
          this.profile2 = this.profile.profile
          this.languages = this.profile.profile.languages
          this.rate = this.profile.rateasprofessional
          this.userRates = this.profile.numberofrateasprofessional
          this.notify.$emit('usernamePublicProfile', this.profile)
          // if (!this.rate) {
          //   this.rate = 1
          // }
          this.show = this.profile2.show
          this.approved = this.profile2.approved
          if (this.show && this.approved) {
            this.profileActive = true
            // this.notify.$emit('profileActived', this.profileActive)
          } else {
            this.profileActive = false
            // this.notify.$emit('profileActived', this.profileActive)
          }
          // if ((!this.profile2.description || this.profile2.description === '') && (!this.profile2.education || this.profile2.education === '') && (!this.profile2.experience || this.profile2.experience === '')) {
          //   console.log('entro algo aqui??')
          //   // const publicProfile = document.body
          //   // publicProfile.classList.toggle('noScroll')
          // }

          this.showLanguages = this.languages
          const languageRemove = this.showLanguages.length - 3
          this.showLanguages.splice(3, languageRemove)
        } else {
          this.$router.push('/404')
        }
      })

    document.addEventListener('scroll', this.scroll)
    this.filterRemove = true

    this.notify.$emit('notificaionHiddeNavBarMobile')

    mixpanel.track('View Public-Profile-Listing', {
      ProfesionalID: this.profile._id
    })
  },
  mounted () {
    // document.getElementById('fc_frame').style.display = 'none'
    // setTimeout(() => {
    //   document.getElementById('nav_bar').classList.add('is-hidden-touch')
    // }, 1000)

    document.getElementById('tidio-chat-iframe').style.display = 'none'
  },
  computed: {
    rateInput: function () {
      if (this.rate.toString().includes('.') === false) {
        return this.rate.toFixed(1)
      } else {
        return this.rate
      }
    }

    // vh: function () {
    //   return window.innerHeight * 0.01
    // }
  },

  methods: {
    // Close Modal
    closeModalPublicProfile () {
      document.body.classList.remove('noScroll')
      this.notify.$emit('closeModalPublicProfile')
    },

    clickShowContinue () {
      this.showContinue = true
    },
    handlerScroll () {
      // console.log(this.profile.profile.profilevideo, '<<<<<<<')
      // if (this.profile.profile.profilevideo) {
      //   this.onScrolledFilter()
      // }
      this.scroll()
    },
    displayProgressBar () {
      this.filterRemove = false
      const v = this
      this.nIntervId = setInterval(function () {
        var duration = document.getElementById('bgvid').duration
        var currentTime = document.getElementById('bgvid').currentTime
        v.valueVideo = Math.round((currentTime / duration) * 100)
      }, 500)
    },
    stopVideo () {
      this.filterRemove = true
    },
    scroll () {
      const resp = document.scrollY
      if (resp > 600 && document.getElementById('btn_connect')) {
        document.getElementById('btn_connect').classList.add('btn-connect')
        document.getElementById('btn_connect').style.position = 'fixed'
      } else if (resp <= 600 && document.getElementById('btn_connect')) {
        document.getElementById('btn_connect').classList.remove('btn-connect')
        document.getElementById('btn_connect').style.position = 'absolute'
      }
    },
    onScrolledFilter () {
      const resp = window.scrollY
      if ((document.getElementById('load').style.display === 'none') && (document.getElementById('play').style.display === 'none')) {
        if (resp > 1) {
          document.getElementById('bgvid').pause()
          this.filterRemove = true
          document.getElementById('progressbar').style.display = 'none'
        } else if (resp <= 1) {
          document.getElementById('bgvid').play()
          this.filterRemove = false
          document.getElementById('progressbar').style.display = 'block'
        }
      }
    },
    scrollUnder () {
      window.scroll(0, 600)
    },
    showModal () {
      if (this.user !== undefined && this.user._id === this.profile._id) {
        this.danger('No puedes reservar una cita contigo mism@')
      } else {
        this.modal = true
        mixpanel.track('Book Videocall All', {
          ProfesionalID: this.profile._id
        })
        this.bookingKey++
        const publicProfile = document.body
        publicProfile.classList.add('noScroll')
      }
    },
    hiddeModal () {
      this.modal = false
      const publicProfile = document.body
      publicProfile.classList.remove('noScroll')
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      // const publicProfile = document.body
      // publicProfile.classList.add('siScroll')
    },
    showModalChild () {
      this.modalChild = true
      const publicProfile = document.body
      publicProfile.classList.add('noScroll')
      this.entryContent = false
    },
    hiddeModalChild () {
      this.modalChild = false
      this.entryContent = true
    },
    // paginacion entre componentes de el booking
    next () {
      if (this.currentSteps + 1 < 4) {
        this.currentSteps = this.currentSteps + 1
      }
    },
    prev () {
      if (this.currentSteps - 1 >= 0) {
        this.currentSteps = this.currentSteps - 1
      }
    },
    // Success Toast
    success (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    },

    // Danger Toast
    danger (msg) {
      this.$buefy.toast.open({
        duration: 4000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  },
  // beforeDestroy: function () {
  //   this.keyPublicProfile++
  //   this.profile = null
  // },
  destroyed () {
    // this.keyPublicProfile++
    // this.profile = null
    window.removeEventListener('scroll', this.scroll)
    clearInterval(this.nIntervId)
    this.notify.$emit('destroyedVisitingPublicProfile')
    this.notify.$emit('destroyedNotificaionHiddeNavBarMobile')
    document.getElementById('tidio-chat-iframe').style.display = 'block'
    // document.getElementById('fc_frame').style.display = 'block'
    // const publicProfile = document.body
    // publicProfile.classList.remove('noScroll')
    // document.getElementById('nav_bar').classList.remove('is-hidden-touch')
  }
}
</script>

<style scoped>

.testing_shit {
  height: 65px;
  background-color: red;
}

.topBarMobile {
  display: flex;
  position: absolute;
  z-index: 3;
  height: 54px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.logoImg {
  width: 75px;
  margin-bottom: 20px;
}

.goBackText {
  color: white !important;
  text-shadow: 5px 5px 10px #00000040;
  font-weight: 500;
}

.goBack {
  display: flex;
  position: absolute;
  z-index: 4;
  height: 67px;
  align-items: center;
  gap: 10px;
}

.goBack img {
  width: 27px;
  margin-left: 15px;
}
.link-text-purple-no-decoration {
  border: none;
  background: transparent;
}
/* css modal disabled profile */

.header-modal-disable-profile {
  width: 90%;
  height: 65px;
  background-color: #fff;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  margin: auto;
}

.logo-emitus-modal-disable {
  width: 63.47px;
  height: 14.89px;
}

.modal-profile-desactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 56px;
}
.cancel-modal-profile-desactived {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.image-disabled-modal {
   margin-left: 11px;
  height: 84px;
  margin-top: 35px;
}
.container-profile-desactive {
  display: flex;
  flex-direction: column;
}
.content-profile-desactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-profile-desactive {
  max-width: 450px;
  margin-top:  74px;
}

/* fin css modal disabled profile*/

.name-user-public {
  text-transform: capitalize;
}

.experience-title {
  font-size: 20px;
}
.experience-text {
  margin-top: 40px;
}

.education-title {
  font-size: 20px;
}

.education-text {
  margin-top: 40px;
}

/* Languages */

.container-languages-profile {
  width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(255, 255, 255 0.8);
}

.container-languages-profile p {
  font-size: 12px;
}

/* style */
.noScroll {
  overflow: hidden!important;
}
.siScroll {
  overflow: auto!important;
}
.disabled-icon {
  opacity: 0.3!important;
  cursor: not-allowed!important;
}
.modal-container {
  width: 100%;
  background-color: #fff;
  height:100vh;
  position: fixed;
  z-index: 6;
  overflow: auto;
}
.price-profesional {
  width: 55%;
}
.price-profesional strong {
  color: #fff;
  font-size: 25px;
  width: 55%;
}
.price-profesional span {
  color: #fff;
  font-size: 14.5px;
}
.general-container {
  width: 100%;
}

.image-user img {
  object-fit: cover;
  width: 59px;
  height: 59px;
  border-radius: 50%;
}

.text-white {
  color: #fff;
  font-size: 14px;
}
.container-content-entry {
  max-width: 253px;
  margin: 80px auto 187px auto;
  text-align: center;
}

.header-booking {
  width: 85%;
  max-width: 450px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-profesional {
  width: 80%;
  max-width: 460px;
  margin: 94px auto 103px auto;
}

.content-profesional h2:last-of-type {
  margin-top: 88px;
}

.lenguage-location p {
  font-weight: normal;
  font-size: 12px;
  line-height: 36px;
  color: #FFFFFF;
}

  /* nav bar public */
.block_btn:hover {
  color: #929292!important;
  cursor: not-allowed;
  border-bottom: none!important;
}

.w-75 {
    width: 65px;
}
.nav-pubic {
    position: fixed;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 47px;
    background: #fff;
    border-bottom: 1px solid #F0F0F0;
}
.nav-pubic ul {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;
}
.nav-pubic ul a {
    color: #929292;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-pubic ul a:hover {
    color: #0D1239;
    border-bottom: 1px solid #0D1239;
}
.nav-pubic ul a:last-of-type {
    width: 30px;
}
.nav-pubic ul a:last-of-type:hover {
    background: #fff;
    border-bottom: 1px solid #F0F0F0;
}
.select-a {
    color: #0D1239!important;
    border-bottom: 1px solid #0D1239!important;
}
.disp-none {
    display: none;
}
.btn-connect a {
  /* width: 181px !important; */
  margin: 0 25px 0 auto!important;
  box-shadow: 0px 7px 14px rgba(13, 18, 57, 0.29);
  border-radius: 18px;
}
.btn_info {
  z-index: 5;
  position: absolute;
  font-size: 14px;
  bottom: 138px;
  right: 19px;
  width: 86px;
  height: 44px;
  border: 0.9px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 18px;
  background: transparent;
  color: #FFFFFF;
}

.rate .rate-text.is-small {
  font-size: 11.5px;
  font-family: "Nunito";
  color: #5c5c5c !important;
  margin-left: 12px;
}

.value-rate {
  width: 35px;
  font-family: "Basier Circle Medium";
  background: transparent;
  border: none;
  outline: none;
  color: #fff!important;
  height: 40px !important;
}
.control .minus {
  display: none!important;
}
.control .plus {
  display: none!important;
}
.progress-wrapper .progress {
  height: 3px!important;
}

.container-tag-line {
  text-align: left;
  position: absolute;
  bottom: 94px;
}
/* prueba */
.filter-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  position: absolute;
  top: 0px;
  opacity: 0;
  background-color: #0d123933;
}
.invisible-front {
  width: 100%;
  height: 100vh;
  z-index: 5;
  top: 0px;
  opacity: 0;
  background-color: #0d123900;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: -75px;
}

.is-horizontal-center {
  justify-content: center;
}
.container-btn-Join-emitus {
  position: absolute;
  z-index: 5;
  bottom: 0;
  width: 100%;
  height: 84px;
  display: flex;
  align-items: center;
  text-align: center;
  background: rgba(13, 18, 57, 0.96);
  padding: 0 10px;
  gap: 10px;
}
.container-btn-Join-emitus  a {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-Join-emitus {
  background: #0dc366;
  box-shadow: 0px 4px 8px rgba(17, 23, 71, 0.17);
  border-radius: 0px;
  width: 256px;
  font-size: 14px;
  font-weight: 600;
  height: 45px;
  color: #ffffff;
  border: none;
}

.btn-Join-emitus:hover, .btn-Join-emitus:focus, .btn-Join-emitus:active {
  background: #32af5c;
  transition-timing-function: ease-out;
  transition-delay: 2s;
  transform: scale(1.04);
  color: #fff;
}

.emitus-video-landing video {
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}
.filter-video-landing {
  position: absolute;
  top: 0;
}
.content-filter {
  color: #ffffff;
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 100%;

}
.content-video-center {
  width: 90%;
}

.content-video-center p {
  font: "Basier Circle Medium";
  max-width: 210px;
}
.container_social {
  width: 100%;
  margin-bottom: 125px;
  display: flex;
  gap: 25%;
  /* justify-content: space-evenly; */
}
.container_social a {
  cursor: pointer;
}

@media (min-width: 959px) {

  .content-modal-container {
    margin-top: 16vh;
  }

  .image-user img {
    width: 83px;
    height: 83px;
  }

  .container-content-entry {
    margin: 110px auto 187px auto;
  }

  .text-white {
    padding-top: 10px;
  }

  .modal-container {
    width: 45%;
    max-width: 504px;
    height:100vh;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 0;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
}

@media only screen and (min-width: 1024px) {

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 540px;
    margin-top: 0px;
  }
  .is-horizontal-center {
    justify-content: center;
  }
  .hero-body {
    padding: 0;
  }

  .hero.is-fullheight {
    background-color: #0d1239;
  }
  .control {
    max-width: 100%;
  }
  .filter-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    position: absolute;
    top: 0;
    opacity: 1;
    background-color: transparent;
    border-radius: 0px;
    overflow: hidden;
  }

  .invisible-front {
    width: 100%;
    height: 100vh;
    z-index: 5;
    top: 0px;
    opacity: 1;
    background-color: transparent;
  }

}

@media (min-width: 1024px) {

  .goBack {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
  }
  .goBack img {
    margin-left: 20px;
  }
  .goBackText {
    color: #474849 !important;
    text-shadow: none;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
    padding-top: 4px;
  }
  /* css modal profile disabled*/

  .logo-emitus-modal-disable {
    width: 82px;
    height: 17.24px;
  }

  .button-extra-small {
    width: 97px;
  }

  .image-disabled-modal {
    margin-top: 0px;
  }
  .container-profile-desactive {
    flex-direction: row;
    margin-top: 186px;
  }

  /* styles languages */

  .container-language-public-desktop {
    width: 100%;
  }
  .lenguage-location p {
    font-size: 13px;
    color: #929292;
    align-self: self-end;
  }

  .city-user-public {
    font-size: 14px !important;
    color: #929292 !important;
  }

  .container-rating-desktop {
    max-width: 250px;
  }

  /* styles*/

  .container_avatar_public {
    height: 100%;
  }
  .img_public_avatar {
    height: 100%;
  }

  .title-bold {
    font-weight: 400;
    font-size: 30px;
    color: #0D1239;
  }
  .container-btn-Join-emitus-desktop {
    position: absolute;
    top: 572px;
  }

  .btn-Join-emitus {
    box-shadow: none !important;
  }

  .container-tag-line-2 {
    width: 90%;
    margin: 0 auto;
  }
  .price-profesional strong {
    font-size: 28.8px;
    color: #0D1239;
  }
  .price-profesional span {
    font-size: 16.9px;
    color: #0D1239;
  }
  /*
  .container-tag-line-2 p {
    color: #8E8E8E;
    margin-top: 0;
    font-size: 20px;

  }*/

  .value-rate {
    color:#0D1239!important;
    font-family: "Basier Circle Medium" !important;
  }

  .value-rate input {
    height: 41px !important;
  }
  .nav-pubic {
    display: block!important;
    border-bottom: none;
    left: 0;
    top: 168px;
    width: 20%;

  }
  .nav-pubic ul {
    flex-direction: column;
  }
  .nav-pubic ul a {
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .select-a {
    border-bottom: none!important;
  }
  .container_content_public {
    width: 60%;
    padding-left: 80px;
    max-width: 423px;
  }
  .container_desktop_content {
    display: flex;
    width: 69%;
    margin:  auto;
    justify-content: center;
    gap: 15px;
    padding-top: 111px;
  }
  .container-tag-line {
    display: none!important;
  }
  .hidde-join-mobile {
    position: fixed!important;
    top: 0px!important;
  }
  .margin_join_fixed {
    margin: 0 0 0 auto!important;
  }
  .container-btn-Join-emitus  {
    display: none!important;
  }

  .join-landing {
    width: 90% !important;
    margin: 140px auto 0 auto;
    top: 0px;
    display: block !important;
  }
  .emitus-video-landing {
    width: 297px;
    height: 524.02px!important;
    border-radius: 5px;
    overflow: hidden;
  }
  .emitus-video-landing video {
    width: 297px;
    height: 524.02px!important;
    border-radius: 5px;
    object-fit: cover;
  }
  .social {
    display: flex;
    height: 540px;
    width: 25px!important;
    order: -1;
    align-items: flex-end;
  }
  .container_social {
    flex-direction: column;
    margin-bottom: 0!important;
  }
  .container_social a {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .content-profesional {
    width: 57%;
    padding: 0px 25px 125px 25px;
    margin-top: 120px;
    max-width: 700px;
  }

  .content-profesional h2:last-of-type {
    margin-top: 65px;
  }
  .logo-emitus-public-profile {
    margin-left: 5%;
  }
  .filter-video-landing {
    width: 297px;
    height: 524.02px!important;
    border-radius: 25.6034px;
    background: transparent;
    z-index: 2;

  }
  .filter-invisible {
    height: 100%;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  .video-height-video-desktop {
    height: 100vh;
  }

  .visible-desktop {
    display: block;
  }
}

@media (max-width: 1024px) {

  .logo-emitus-public-profile {
    width: 75px;
  }
    .hidde-join-mobile {
    display: none!important;

  }
  .social {
    width: 80%;
    max-width: 460px;
    margin: 65px auto 0 auto;
    padding-bottom: 7px;
  }
  .container_avatar_public {
    height: 100vh;
    width: 100vw;
  }
  .img_public_avatar {
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }
  .join-position-mobile {
    z-index: 0;
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
  }

  .filter-video-landing {
    height: calc(var(--vh, 1vh) * 100);
    background: linear-gradient(
      180.81deg,
      rgba(13, 18, 57, 0.136) 0.66%,
      rgba(13, 18, 57, 0.704) 74.9%,
      rgba(13, 18, 57, 0.8) 99.3%
    );
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .filter-invisible {
    height: 100vh;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
  }

  .content-video-center h4 {
    width: 271px;
  }

}

</style>
